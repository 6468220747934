import { __assign } from "tslib";
import { get, extend, merge, trimEnd, pickBy, noop } from 'lodash';
import { normalizeUrl } from '../uri';
import { retry } from './retry-request';
var DEFAULT_TIMEOUT = 12000;
export default function createRequest(_a) {
    var _b = _a.apiBaseUrl, apiBaseUrl = _b === void 0 ? '' : _b, _c = _a.instance, instance = _c === void 0 ? '' : _c, _d = _a.getInstance, getInstance = _d === void 0 ? function () { return ''; } : _d, instanceId = _a.instanceId, _e = _a.locale, locale = _e === void 0 ? 'en' : _e, cookie = _a.cookie, _f = _a.timeout, timeout = _f === void 0 ? DEFAULT_TIMEOUT : _f, _g = _a.performanceTracker, performanceTracker = _g === void 0 ? { trackStart: noop, trackEnd: noop, parseHeader: noop } : _g, _h = _a.additionalHeaders, additionalHeaders = _h === void 0 ? {} : _h, _j = _a.v2, v2 = _j === void 0 ? false : _j, httpClient = _a.httpClient;
    var client = httpClient.client;
    var request = function (path, config) {
        if (config === void 0) { config = {}; }
        var source = httpClient.cancelTokenSource();
        var url = normalizeUrl("".concat(trimEnd(config.apiBaseUrl || (v2 ? "".concat(apiBaseUrl, "/v2") : apiBaseUrl), '/')).concat(path));
        var shouldParseHeaders = config.parseHeaders;
        var retryCount = config.retry;
        var abortSignal = config.abortSignal;
        delete config.abortSignal;
        delete config.retry;
        delete config.parseHeaders;
        delete config.v2;
        if (config.timeout) {
            timeout = config.timeout;
        }
        instance = instance || getInstance();
        var headers = pickBy(__assign(__assign(__assign({ 'Content-Type': 'application/json' }, config.headers), { instance: instance, instanceId: instanceId, Authorization: instance, locale: locale, cookie: cookie }), additionalHeaders));
        extend(config, {
            headers: headers,
            timeout: timeout,
            credentials: get(config, 'credentials', 'same-origin'),
        }, request.meteredPathKey ? { meteredPathKey: request.meteredPathKey } : {});
        var marker = performanceTracker.trackStart("".concat(new Date().toISOString().slice(11), " ").concat(config.method || 'GET', " ").concat(path));
        var start = function () {
            var promise = client.request(__assign(__assign({ url: url }, config), { cancelToken: source.token }));
            promise.abort = function () { return source.cancel('Fetch was aborted'); };
            return promise;
        };
        var parse = function (res) {
            return Promise.resolve(res)
                .then(function (response) {
                performanceTracker.trackEnd(marker, performanceTracker.parseHeader(response));
                return response;
            })
                .then(shouldParseHeaders ? parseHeadersAndBody : parseBody);
        };
        return retry(start, url, retryCount, timeout, abortSignal, httpClient.isCancel).then(parse);
    };
    var defineVerb = function (method) { return function (path, data, config) {
        return request(path, merge({}, config, {
            method: method,
            headers: {
                'Content-Type': 'application/json',
            },
            data: JSON.stringify(data),
        }));
    }; };
    request.post = defineVerb('POST');
    request.put = defineVerb('PUT');
    request.patch = defineVerb('PATCH');
    request.delete = function (path, config) {
        return request(path, extend({}, config, {
            method: 'DELETE',
        }));
    };
    request.metered = function (meteredPathKey) {
        return Object.assign(request, { meteredPathKey: meteredPathKey });
    };
    return request;
}
function parseBody(response) {
    return response.data;
}
function parseHeadersAndBody(response) {
    return {
        body: response.data,
        headers: new Headers(response.headers),
    };
}
